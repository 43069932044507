import { WeaponsModel } from '@/common/models/weapons.model'
import { SelectedComponent } from '@/common/types/components.types'

export function weaponsDataTransform(
  data: WeaponsModel[],
): SelectedComponent[] {
  if (data) {
    const reformatResults = data.map(({ id, name }) => {
      const simplifyName = name.replace(/\s+/g, '-').replace(/\./g, '')
      const data = {
        id: id,
        label: simplifyName,
        value: id,
        icon: weaponsIconGenerator(name),
      } as SelectedComponent
      return data
    })

    const sort = sortWeaponsAlphabetically(reformatResults)

    return sort ?? []
  }
  return []
}

const weaponsIconGenerator = (weaponsName: string): object => {
  const cleanName = weaponsName.replace(/\s+/g, '-').toLowerCase()

  const checkIconList = weaponsIcons.find((item) =>
    item.name.includes(cleanName),
  )

  if (checkIconList) {
    return {
      symbol: checkIconList.name,
      width: checkIconList.width,
      height: checkIconList.height,
    }
  } else {
    return {
      symbol: 'weapons',
      width: '40px',
      height: '40px',
    }
  }
}

const sortWeaponsAlphabetically = (weaponsList: SelectedComponent[]) => {
  return weaponsList.sort((a: SelectedComponent, b: SelectedComponent) =>
    a.label.localeCompare(b.label),
  )
}

const weaponsIcons = [
  {
    name: 'archery',
    width: '35px',
    height: '47px',
  },
  {
    name: 'crossbow',
    width: '35px',
    height: '47px',
  },
  {
    name: 'handgun',
    width: '35px',
    height: '47px',
  },
  {
    name: 'muzzleloader',
    width: '35px',
    height: '47px',
  },
  {
    name: 'rifle',
    width: '35px',
    height: '47px',
  },
  {
    name: 'shotgun',
    width: '35px',
    height: '47px',
  },
]
