import {
  FeatureFlags,
  FeatureFlagsKey,
  FEATURES,
} from '@/split/featuresFlags.model'
import { SplitFactory } from '@splitsoftware/splitio'
import SplitIO from '@splitsoftware/splitio/types/splitio'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useSplitStore = defineStore('split', () => {
  const client = ref<SplitIO.IBrowserClient | null>(null)
  const isReady = ref<boolean | null>(null)
  const treatments = ref<SplitIO.Treatments | null>(null)
  const featureFlags = ref<FeatureFlags | null>(null)

  function init(userId: string) {
    return new Promise<void>((resolve, reject) => {
      const factory: SplitIO.IBrowserSDK = SplitFactory({
        core: {
          authorizationKey: window.environment.splitio_key,
          key: userId,
        },
        startup: {
          readyTimeout: 1.5,
        },
      })
      client.value = factory.client()
      client.value.once(client.value.Event.SDK_READY, () => {
        isReady.value = true
        resolve()
      })
      client.value.once(client.value.Event.SDK_READY_TIMED_OUT, () => {
        isReady.value = false
        reject()
      })
    })
  }

  function getTreatments() {
    return new Promise<void>((resolve, reject) => {
      const results = client.value?.getTreatments(FEATURES)
      if (results) {
        treatments.value = results
        resolve()
      }
      reject()
    })
  }

  function updateFeatureFlag(name: FeatureFlagsKey, value: string | boolean) {
    if (featureFlags.value) {
      featureFlags.value[name] = value
    }
  }

  function _parseTreatment(treatment: SplitIO.Treatment): string | boolean {
    if (treatment === 'on' || treatment === 'control' || treatment === 'off') {
      return treatment === 'on'
    }

    return treatment
  }

  function _convertTreatmentsToFeatureFlags(
    treatments: SplitIO.Treatments,
  ): unknown {
    const flags: { [name: string]: boolean | string } = {}
    Object.entries(treatments).forEach(([key, val]) => {
      flags[key] = _parseTreatment(val)
    })
    return flags
  }

  watch(treatments, (value) => {
    if (value) {
      featureFlags.value = _convertTreatmentsToFeatureFlags(
        value,
      ) as FeatureFlags
    }
  })

  return {
    treatments,
    isReady,
    featureFlags,
    init,
    getTreatments,
    updateFeatureFlag,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSplitStore, import.meta.hot))
}
