import { isLocal } from '@/env'
import { initIdentityClient as init } from '@onxmaps/identity-client'
import random from 'lodash/random'

export function initIdentityClient() {
  const { identity_authority, identity_client_id } = window.environment
  const staggeredRenewTimeInSeconds = random(60, 60 * 5)
  init({
    authority: identity_authority,
    client_id: identity_client_id,
    redirect_uri: `${window.origin}/auth/callback`,
    silent_redirect_uri: `${window.origin}/auth/silent-renew`,
    post_logout_redirect_uri: `${window.origin}/auth/post-logout`,
    scope: 'openid email profile internal',
    logs_enabled: isLocal(),
    silentRenewTimeInSecondsBeforeAccessTokenExpiration:
      staggeredRenewTimeInSeconds,
  })
}
