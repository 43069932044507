import { AppEvent } from '@/common/types/events.types'
import { initIdentityClient } from '@/modules/auth/identity-client'
import pubsub from '@/pubsub'
import {
  completeSignin,
  completeSignoutSilent,
  hasValidUserSession,
  signinRedirect,
} from '@onxmaps/identity-client'

const AuthLayout = () => import('@/modules/auth/AuthLayout.vue')
const AuthLogin = () => import('@/modules/auth/views/AuthLoginView.vue')
const AuthSilentRenew = () =>
  import('@/modules/auth/views/AuthSilentRenewView.vue')
const AuthPostLogout = () => import('@/modules/auth/views/AuthPostLogout.vue')

const routes = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: AuthLogin,
        // @ts-expect-error ignore vue-router for now
        beforeEnter: async (to) => {
          try {
            initIdentityClient()
            const isLoggedIn = await hasValidUserSession()
            if (!isLoggedIn) {
              signinRedirect({
                state: {
                  after_login: to,
                },
              })
            }
            return false
          } catch (error) {
            console.log('login error', error)
            return false
          }
        },
      },
      {
        path: 'silent-renew',
        name: 'silent-renew',
        component: AuthSilentRenew,
        async beforeEnter() {
          try {
            initIdentityClient()
            await completeSignin()
            return false
          } catch (error) {
            console.log('silent-renew error', error)
            return false
          }
        },
      },
      {
        path: 'callback',
        name: 'callback',
        component: AuthLogin,
        beforeEnter: async () => {
          try {
            initIdentityClient()
            const user = await completeSignin()

            if (user) pubsub.publish(AppEvent.UserLogin, { user })

            return {
              // @ts-expect-error ignore vue-router for now
              path: user.state?.after_login.redirectedFrom.path,
              // @ts-expect-error ignore vue-router for now
              query: user.state?.after_login.redirectedFrom.query,
            }
          } catch (error) {
            console.log('callback error', error)
            return { name: 'search' }
          }
        },
      },
      {
        path: 'post-logout',
        name: 'post-logout',
        component: AuthPostLogout,
        async beforeEnter() {
          try {
            initIdentityClient()
            await completeSignoutSilent()
            return false
          } catch (error) {
            console.log('post-logout error', error)
            return '/'
          }
        },
      },
    ],
  },
]

export default routes
